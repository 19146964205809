import React from "react"
import styles from "./career-css-module.module.scss"
import carrierBg from "../../static/carrierBg.png"
import LIFE2 from "../assets/career/SL11.png"
import LIFE3 from "../assets/career/SL88.png"
import LIFE4 from "../assets/career/SL99.png"
import LIFE5 from "../assets/career/SL66.png"
import LIFE6 from "../assets/career/SL77.png"
import LIFE7 from "../assets/career/SL22.png"
import LIFE8 from "../assets/career/SL55.png"
//import LIFE9 from "../assets/career/SL44.png"
import { Layout, CurrentJobs, SEO } from "../components"

const Careers = () => {
  return (
    <div>
      <Layout>
        <SEO
          title="Bangalore | Noida jobs | Fintech careers | B2B Jobs |Apply for jobs"
          description="Looking for opportunities to learn and grow in a fast growing B2B tech startup? Apply for a position in Solv today!"
        />
        <section className={styles.container}>
          <div className={styles.about}>
            <img src={carrierBg} alt="logo" />
            <div className={styles.growthPlatform}>
              <div className={[styles.heading, "heading1SemiBold"].join(" ")}>
                Come and help us transform the small business ecosystem of India
              </div>
              <div className={styles.description}>
                Small and medium businesses are the lifeblood of India’s
                economy. At Solv, we are transforming the way these SMEs do
                business. For good.
              </div>
              <div className={styles.descriptions}>
                {" "}
                Do you want to stand on the sidelines as the trajectory of India
                changes? Or do you want to help make it happen? If you’re
                looking to build an India-first product that impacts millions of
                lives, let’s talk!
              </div>
            </div>
          </div>
        </section>
        {/* <section>
                  <div className={styles.chooseSolvBg}>
                  <div className={styles.whySolv}>Why Choose Solv ?</div>
                    <div className={styles.reasonsContainer}>
                        <div className={[styles.reason, styles.one].join(' ')}>
                            <WhySolv number="1" icon="career1" label="Flexible working hours" description="We don’t have “office timings”. We’re not a 9-5 “job”."/>                         
                        </div>
                        <div className={[styles.reason, styles.four].join(' ')}>
                            <WhySolv number="4" icon="career2" label="Remote work" description="Get things done - from home or an office"/>
                        </div>
                        <div className={[styles.reason, styles.two].join(' ')}>
                            <WhySolv number="2" icon="career3" label="Leader’s support " description="Ask for help, give and get feedback, set up goals and develop your skills."/>
                        </div>
                        <div className={[styles.reason, styles.five].join(' ')}>
                            <WhySolv number="5" icon="career4" label="Mentoring" description="Start your adventure with guidance and support of a mentor from the first day."/>
                        </div>
                        <div className={[styles.reason, styles.three].join(' ')}>
                            <WhySolv number="3" icon="career5" label="Knowledge" description="sharing Internal webinars, regular workshops and rich knowledge base."/>
                        </div>
                        <div className={[styles.reason, styles.six].join(' ')}>
                            <WhySolv number="6" icon="career6" label="Private health insurance" description="We care about you. We want to meet your healthcare needs."/>
                        </div>
                    </div>
                  </div>
                </section> */}
        <section>
          <div className={styles.chooseSolvBg}>
            <div className={[styles.whySolv, "heading1Medium"].join(" ")}>
              Current Job Openings
            </div>
            <div className={styles.jobSectionBg}>
              <CurrentJobs />
              <div className={styles.lifeAtSolvContainer}>
                <div className={[styles.heading, "heading1Medium"].join(" ")}>
                  Life and culture at Solv
                </div>
                <div className={styles.description}>
                  Life as a Solver is challenging, exhilarating and fun.
                </div>
                <div className={styles.imagesGallery}>
                  {/* <div className={styles.item1}>
                    <img src={LIFE1} alt="logo" />
                  </div> */}
                  <div className={styles.item2}>
                    <img src={LIFE2} alt="logo" />
                  </div>
                  <div className={styles.item3}>
                    <img src={LIFE3} alt="logo" />
                  </div>
                  <div className={styles.item4}>
                    <img src={LIFE4} alt="logo" />
                  </div>
                  <div className={styles.item5}>
                    <img src={LIFE5} alt="logo" />
                  </div>
                  <div className={styles.item6}>
                    <img src={LIFE6} alt="logo" />
                  </div>
                  <div className={styles.item7}>
                    <img src={LIFE7} alt="logo" />
                  </div>
                  <div className={styles.item8}>
                    <img src={LIFE8} alt="logo" />
                  </div>
                  {/* <div className={styles.item9}>
                    <img src={LIFE9} alt="logo" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <section className={styles.disclaimer}>
            <div className={styles.disclaimerContent}>
              <div className={styles.disclaimer_heading}>Disclaimer:</div>
              <hr />
              <div className={styles.disclaimer_text}>
                Solv and its employees conform to a strict code of conduct and
                governance standard. Solv has not authorized anyone, including
                employees, to offer employment in exchange of cash or such other
                consideration. In the event any person chooses to offer cash or
                such benefit to an employee of Solv or any other person for
                receiving direct or indirect employment at Solv, please note
                that Solv has not authorized any such transaction and Solv will
                not be held liable for any losses or damage incurred. In the
                event any person approaches you with such offers, please let us
                know by sending an email to
                <span className={styles.disclaimer_email}>
                  <a href="mailto: human.resources@solvezy.com">
                    {" "}
                    human.resources@solvezy.com
                  </a>
                </span>
                
              </div>
            </div>
          </section>
        </section>
      </Layout>
    </div>
  )
}

export default Careers
